/* Width for first column */
div.playerProfile table tr th:nth-child(1),
div.playerProfile table tr td:nth-child(1) {
    width: 150px;
}

.playerProfile table {
    width: 100%; /* Or any specific width */
    table-layout: auto;
}

.playerProfile table th, .playerProfile table td {
    box-sizing: border-box;
}

.playerProfile td span:hover{
    text-decoration: underline;
}

.playerProfile{
    font-size: 13px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .playerProfile{
        font-size: 16px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
}