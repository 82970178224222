
.clubMenu {
    display: flex;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 20px;
    font-size:13px;
}

.clubMenu .clubListMenuItem {
    border: none;
    background: none;
    cursor: pointer;
}

.clubMenu .clubListMenuItem.active {
    color: rgb(19, 20, 21);
    position: relative; /* Needed to position the underline */
}

.underline {
    height: 2px;
    background-color: black;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0; /* Initial width */
    transition: all 0.3s ease; /* Smooth transition for moving and resizing */
}

.clubMenu .clubListMenuItem span {
    color: #70757a;
}

.clubMenu .clubListMenuItem.active span {
    color: black;
}

.clubMenu .clubListMenuItem {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 20px;
    padding-bottom: 0px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .clubMenu{
        font-size: 16px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
}