/* Style for the dropdown button */
/* .dropdown .dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin: 5px;
} */

/* The container <div> - needed to position the dropdown content */
/* .dropdown {
    position: relative;
    display: inline-block;
} */

/* Dropdown Content (Hidden by Default) */
/* .dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
} */

/* Links inside the dropdown */
/* .dropdown .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
} */

/* Change color of dropdown links on hover */
/* .dropdown .dropdown-content a:hover {
    background-color: #f1f1f1;
} */

/* Add some spacing between dropdowns */
/* .dropdown + .dropdown {
    margin-left: 10px;
} */

.dropdown span{
    margin-right: 10px;;
}
