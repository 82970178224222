nav {
  background-color: #333; /* Dark background */
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex; /* Flex layout for inline styling of elements */
  align-items: center; /* Align items vertically */
  width: 100%;
}
  
nav a {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 20px; /* Space between links */
  font-size: 18px; /* Larger font size */
}
  
nav a:hover {
  color: #ddd; /* Color change on hover */
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}