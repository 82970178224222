/* Width for first column */
div.leagueProfile table tr th:nth-child(1),
div.leagueProfile table tr td:nth-child(1) {
    width: 150px;
}

.leagueProfile table {
    width: 100%; /* Or any specific width */
    table-layout: auto;
}

.leagueProfile table th, .leagueProfile table td {
    box-sizing: border-box;
}

.leagueProfile{
    font-size: 13px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .leagueProfile{
        font-size: 16px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
}