/* Width for first column */
div.topPerformersList table tr th:nth-child(1),
div.topPerformersList table tr td:nth-child(1) {
    width: 200px;
}

/* Width for first column */
div.topPerformersList table tr th:nth-child(2),
div.topPerformersList table tr td:nth-child(2) {
    width: 150px;
}

/* Width for first column */
div.topPerformersList table tr th:nth-child(3),
div.topPerformersList table tr td:nth-child(3) {
    width: 200px;
}

/* Width for first column */
div.topPerformersList table tr th:nth-child(4),
div.topPerformersList table tr td:nth-child(4) {
    width: 200px;
}

/* Add alternating row colors */
.topPerformersList table tbody tr:nth-child(odd) {
    background-color: #f2f2f2; /* Set the background color for odd rows */
}

.topPerformersList table tbody tr:nth-child(even) {
    background-color: #ffffff; /* Set the background color for even rows */
}

.topPerformersList td span{
    cursor: pointer;
}

.topPerformersList td span:hover{
    cursor: pointer;
    text-decoration: underline;
}

.topPerformersList{
    min-height: 450px;
}

.topPerformersList.loading{
    padding-top: 10px; 
    margin-top: 20px; 
}

.topPerformersList table {
    width: 100%;
    table-layout: auto;
    /* overflow-x: auto; */
}

.topPerformersList .table-container {
    overflow-x: auto; /* Enable horizontal scrolling */
}

.topPerformersList {
    font-size: 13px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .topPerformersList {
        font-size: 16px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
}