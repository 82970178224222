* {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body{
  margin-bottom: 20px;
}

a{
  text-decoration: none;
}

/* Add space between columns */
table td,
table th {
    padding: 8px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

table th {
    text-align: left;
    padding: 6px;
    background-color: #333;
    color: #fff;
}

table td {
  padding: 7px;
}

table {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-collapse: collapse;
    /* table-layout: fixed; */
}

.pagination-button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color:#007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.pagination-button:hover {
  background-color: #0056b3;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: default;
}

img{
  max-width: 100px;
}

.submitBtn{
  background-color:#007bff;
  padding: 5px 10px;
  /* margin: 0 5px; */
  border: none;
  background-color:#007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.submitBtn:hover{
  background-color: #0056b3;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
}