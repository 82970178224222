.searchBox form {
    display: flex;
    /* Align input and button */
    gap: 10px;
    /* Space between input and button */
}

.search-container {
    position: relative; /* Relative positioning for the container */
    display: flex;
    align-items: center;
}

.searchBox input[type="text"] {
    padding: 8px;
    border: none;
    border-radius: 4px;
    /* Rounded corners */
    outline: none;
    width: 100px; 
    margin-left: 20px;
    background-color:#f2f2f2;
    /* Removes the outline */
}

.searchBox button {
    color: white;
    border: none;
    padding: 5px 10px; /* Reduced padding */
    font-size: 10px; /* Smaller font size */
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    position: absolute; /* Absolute positioning for the button */
    right: 5px; /* Positioned to the right inside the input */
    background-image: url('../assets/right-arrow.png'); /* Path to your image */
    background-size: cover; /* Or 'contain', depending on your image */
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: normal;
    width: 30px; /* Adjust based on your image size */
    height: 30px; /* Adjust based on your image size */
    text-indent: -9999px; /* Hide the text */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .searchBox input[type="text"] {
        width: 250px; /* Adjust this value as needed */
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
}